import React from 'react';
import { css } from 'glamor';
import glamorous from 'glamorous';

const { Div, Svg, Path } = glamorous;

const leftAnimation = css.keyframes({
  '10%': { transform: 'translateX(0)' },
  '50%': { transform: 'translateX(8px)' },
  '70%': { transform: 'translateX(-1px)' },
  '90%': { transform: 'translateX(0)' }
});

const rightAnimation = css.keyframes({
  '10%': { transform: 'translateX(0)' },
  '50%': { transform: 'translateX(-8px)' },
  '70%': { transform: 'translateX(1px)' },
  '90%': { transform: 'translateX(0)' }
});

const Loading = ({ fullPage, size = 40, color = '#1A2328', white }) => (
  <Div
    display="flex"
    alignItems="center"
    justifyContent="center"
    height={fullPage ? '100vh' : 'auto'}
    width={fullPage ? '100vw' : 'auto'}
    padding={20}
  >
    <Svg
      viewBox="0 0 59 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      css={{
        display: 'block',
        height: fullPage ? 80 : size,
        overflow: 'visible'
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-250.000000, -50.000000)"
          fill={white ? '#fff' : color}
        >
          <g transform="translate(250.000000, 50.000000)">
            <path d="M8.00566045,21.2558075 L29.6150424,0.226653254 L50.8148243,21.2558075 L29.6150424,42.2849617 L8.00566045,21.2558075 L8.00566045,21.2558075 Z M12.2458494,21.2558075 L29.6150424,4.43242643 L46.5749261,21.2558075 L29.6150424,38.0791886 L12.2458494,21.2558075 L12.2458494,21.2558075 Z" />
            <Path
              d="M0.101455227,21.2113996 L21.3457147,0.138125838 L42.5896834,21.2113996 L21.3457147,42.2846734 L0.101455227,21.2113996 L0.101455227,21.2113996 Z M4.56635733,21.2113996 L21.3457147,4.56709191 L38.125072,21.2113996 L21.3457147,37.8557073 L4.56635733,21.2113996 L4.56635733,21.2113996 Z"
              animation={`${leftAnimation} 1.25s infinite ease`}
            />
            <Path
              d="M16.779648,21.4862095 L25.7841628,12.5538797 L26.964997,13.7254982 L19.1413164,21.4862095 L26.9769158,29.2587436 L25.7960816,30.4300738 L16.779648,21.4862095 L16.779648,21.4862095 Z M28.6330488,9.18580079 L37.4898867,0.400247732 L58.7466463,21.4859211 L37.4898867,42.5713061 L28.3801375,33.535454 L30.042666,33.535454 L30.3624389,33.1585637 L37.4898867,40.2286458 L56.3849779,21.4859211 L37.4898867,2.74290809 L29.8734771,10.2983049 L28.6330488,9.18580079 L28.6330488,9.18580079 Z"
              animation={`${rightAnimation} 1.25s infinite ease`}
            />
          </g>
        </g>
      </g>
    </Svg>
  </Div>
);

export default Loading;
