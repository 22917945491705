import { createMuiTheme } from '@material-ui/core/styles';
import { FONT_FAMILY, COLORS } from '../constants/theme';

export default createMuiTheme({
  typography: {
    fontFamily: FONT_FAMILY
  },
  palette: {
    primary: {
      main: COLORS.primary,
      contrastText: '#fff'
    },
    secondary: {
      main: COLORS.burntSienna
    },
    error: {
      main: COLORS.burntSienna
    },
    text: {
      primary: COLORS.text
    }
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '14px',
      }
    }
  }
});

export const darkTheme = createMuiTheme({
  typography: {
    fontFamily: FONT_FAMILY
  },
  palette: {
    type: 'dark',
    primary: {
      main: COLORS.primary,
      contrastText: '#fff'
    },
    secondary: {
      main: COLORS.burntSienna
    },
    error: {
      main: COLORS.burntSienna
    }
  }
});
