const KEY = 'authToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

export const setToken = token => {
  localStorage.setItem(KEY, token);
};

export const setRefreshToken = token => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(KEY);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getToken = () => {
  return localStorage.getItem(KEY);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};
