import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

class Analytics extends Component {
  componentDidMount() {
    const { history } = this.props;

    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.unlisten = history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(Analytics);
