const COLOR_NAMES = {
  mirage: '#1A2328',
  jade: '#02B875',
  match: '#009A5A',
  cadetBlue: '#A0A8BD',
  catskillWhite: '#F5F7FA',
  burntSienna: '#E76049',
  jungleGreen: '#2EB398',
  scotchMist: '#FFF9DB',
  tequila: '#FFE8CC',
  yellowOrange: '#FFA94D',
  alto: '#E0E0E0',
  athensGray: '#F7F8FA',
  mischka: '#E3E5EB',
  ghost: '#CACFDB',
  malibu: '#5AAAFA',
  rollingStone: '#6F7C82',
  kournikova: '#FFD875',
  transparentWhite: 'rgba(255, 255, 255, 0.5)'
};

export const COLORS = {
  ...COLOR_NAMES,
  primary: COLOR_NAMES.match,
  text: COLOR_NAMES.mirage,
  introSection: '#F6F9FC'
};

export const FONT_FAMILY = '"roobert", Helvetica, Arial, sans-serif';

export const SPACING = 20;

export const BREAKPOINTS = {
  medium: '@media(min-width: 800px)'
};

export default {
  colors: COLORS,
  fontFamily: FONT_FAMILY,
  spacing: SPACING,
  breakpoints: BREAKPOINTS
};
