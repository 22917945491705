/**
 * Returns the runtime environment based on the URL.
 *
 * app.mvpfactory.co => 'production'
 * mvpf-platform-app-staging.herokuapp.com => 'staging'
 * mvpf-platform-app-pr-85.herokuapp.com => 'pr'
 * anything else => 'local'
 */
const getEnv = () => {
  const { hostname } = window.location;
  const [subdomain, domain] = hostname.split('.');
  if (domain === 'mvpfactory') return 'production';
  if (subdomain.includes('-staging')) return 'staging';
  if (subdomain.includes('-pr-')) return 'pr';
  return 'local';
};

export default getEnv;
