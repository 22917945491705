const getAppUrl = app => {
  const { hostname, origin } = window.location;

  if (hostname === 'localhost') {
    switch (app) {
      case 'api':
        return process.env.REACT_APP_API_URL
          ? process.env.REACT_APP_API_URL
          : 'http://localhost:3000';
      case 'admin':
        return process.env.REACT_APP_ADMIN_URL
          ? process.env.REACT_APP_ADMIN_URL
          : 'http://localhost:3001';
      case 'elite':
        return process.env.REACT_APP_ELITE_URL
          ? process.env.REACT_APP_ELITE_URL
          : 'http://localhost:3002';
      default:
        return null;
    }
  }

  if (origin.includes('admin')) {
    return origin.replace('admin', app);
  }

  if (origin.includes('elite')) {
    return origin.replace('elite', app);
  }
};

export default getAppUrl;
