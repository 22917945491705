import React, { Component } from 'react';
import { Div } from 'glamorous';
import hoistNonReactStatic from 'hoist-non-react-statics';
import * as Sentry from '@sentry/browser';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

class ErrorBoundary extends Component {
  state = {
    error: null,
    eventId: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Div
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding={20}
        >
          <h2>We're sorry - something's gone wrong.</h2>

          <h3>Our team has been notified.</h3>
        </Div>
      );
    }
    return this.props.children;
  }
}

export const withErrorBoundary = WrappedComponent => {
  const WithErrorBoundary = props => (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(
    WrappedComponent
  )})`;

  hoistNonReactStatic(WithErrorBoundary, WrappedComponent);

  return WithErrorBoundary;
};

export default ErrorBoundary;
